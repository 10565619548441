import * as React from 'react';

// 定义组件的 props 接口
interface MathJaxRendererProps {
  text: string; // 包含 LaTeX 公式的文本
}

class MathJaxRenderer extends React.Component<MathJaxRendererProps> {
  // 在组件挂载后初始化 MathJax
  componentDidMount() {
    if (typeof window !== 'undefined' && (window as any).MathJax) {
      (window as any).MathJax.typesetPromise();
    }
  }

  // 在组件更新后重新渲染 MathJax
  componentDidUpdate() {
    if (typeof window !== 'undefined' && (window as any).MathJax) {
      (window as any).MathJax.typesetPromise();
    }
  }

  render() {
    const { text } = this.props;
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  }
}

export default MathJaxRenderer;